<!-- 交易流转资源资产类型 -->
<template>
  <LandCard title="贷款区域排名" :isLeftMore="false">
    <div class="echartStyle">
      <chart :options="options"></chart>
    </div>
  </LandCard>
</template>

<script>
import LandCard from "@/components/Card/landTrustCard.vue";
import chart from "@/components/echart/echart";
import { getDKQYPMInfo } from "@/api/financialService.js";
export default {
  components: {
    LandCard,
    chart,
  },
  data() {
    return {
      options: null,
      areaData: [],
    };
  },
  watch: {
    areaData(val, oldval) {
      this.init(val);
    },
  },
  created() {
    this.getAreaData();
  },
  computed: {
    total() {
      let res = null;
      if (this.areaData && this.areaData.length > 0) {
        this.areaData.map((x) => {
          res += x.value;
        });
      }
      return Math.round(res);
    },
  },
  mounted() {
    this.init(this.areaData);
  },
  methods: {
    async getAreaData() {
      let res = await getDKQYPMInfo();
      if (!res || res.code != 0) {
        return;
      }
      this.areaData = res.data;
      this.areaData.forEach((item) => {
        item.show = true;
      });
    },
    init(obj) {
      const dataList = obj;
      this.options = {
        xAxis: {
          show: false,
        },
        yAxis: {
          show: false,
        },
        tooltip: {
          trigger: "item",
          showContent: true,
          backgroundColor: "rgba(8,36,68,.7)",
          color: "#fff",
          textStyle: {
            color: "#fff",
          },
          //valueFormatter: (value) => value + " " + "万元",
          formatter:function(params){
            var html=params.marker+"  "+"贷款区域"+"："+params.name+"<br>";
            html+=params.marker+"  "+"贷款金额"+"："+params.value+"万元"+"<br>"+params.marker+"  "+"所占比例"+"："+params.percent+"%";
            return html;
          }
        },
        title: {
          show: true,
          top: "43%",
          left: "34%",
          textAlign: "center",
          text: "贷款总金额" + "\n" + this.total + "万元",
          textStyle: {
            color: "#333333",
            fontSize: 16,
            fontWeight: "bold",
            lineHeight: 30,
          },
        },
        legend: {
          selectedMode: true, // 图例上的点击事件
          orient: "vertical",
          left: "70%",
          bottom: "10%",
          align: "left",
          itemGap: 12,
          itemWidth: 12, // 设置宽度
          itemHeight: 12, // 设置高度
          //symbolKeepAspect: false,
          textStyle: {
            color: "#000",
            rich: {
              name: {
                verticalAlign: "right",
                align: "left",
                fontSize: 16,
                color: "#71809A",
              },
              value: {
                align: "left",
                fontSize: 18,
                color: "#71809A",
              },
            },
          },
          data: dataList.map((item) => {
            if (item.show) {
              return item.name;
            }
          }),
          formatter: function (name) {
            if (dataList && dataList.length) {
              for (var i = 0; i < dataList.length; i++) {
                if (name === dataList[i].name) {
                  // return "{name| " + name + "}  "+dataList[i].percent;
                  return "{name| " + name + "}  ";
                }
              }
            }
          },
        },
        series: [
          {
            name: "贷款金额",
            type: "pie",
            radius: [78, 102],
            center: ["35%", "50%"],
            avoidLabelOverlap: true,
            startAngle: 200,
            label: {
              normal: {
                show: true,
                formatter: (params) => {
                  //return params.name + " " + params.percent + "%";
                  return params.name
                },
                //lineHeight: 24,
                textStyle: {
                  //fontSize: 14,
                  fontSize: 16,
                  color: "inherit",
                },
              },
            },
            data: dataList,
          },
        ],
      };
    },
  },
};
</script>

<style lang="less" scoped>
.echartStyle {
  height: calc(100% - 50px);
}
</style>
